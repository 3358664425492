import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import {
    HeaderMobileMenu,
    HeaderLogo,
    HeaderSearch,
    HeaderDesktopMenu,
    Inner,
} from 'components';
import RoutePath from '../../routes/constants/routes';
import APILinks from '../../constants/api';
import Breakpoints from '../../themes/constants/breakpoints';
import useAuthAction from '../../hooks/useAuthAction';
import { ReactComponent as GroupIcon } from '../../media/icons/group.svg';

// Styles
const HeaderWrapper = styled.header<{ background?: string }>`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    height: ${({ theme }) => theme.height.header.primary};
    background: ${({ theme, background }) => background || theme.background.header};
    @media only screen and (${Breakpoints.TABLET}) {
        height: ${({ theme }) => theme.height.header.tablet};
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        height: ${({ theme }) => theme.height.header.desktop};
    }
`;
const HeaderInner = styled(Inner)`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0 auto;
    padding: 0;
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        ${({ theme }) => theme.paddings.tablet};
    }
`;
const HeaderSupport = styled.div`
    font-size: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 3px;
    padding-top: 5px;
    width: 100%;
    max-width: 1460px;
    margin: 0 auto;
    
    a, nobr {
        color: #000;
        text-decoration: underline;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        padding-top: 10px;
        justify-content: flex-end;
        ${({ theme }) => theme.paddings.tablet};
    }
`;
const NavButton = styled.button<{ isActive?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    height: inherit;
    color: ${({ theme, isActive }) => (isActive ? theme.palette.primary : theme.palette.black)};
`;
const NavButtonIcon = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 48px;
    user-select: none;
    & > svg{
        width: 24px;
        height: 24px;
        max-width: 24px;
        max-height: 24px;
    }
    @media only screen and (${Breakpoints.TABLET}) {
        & > svg{
            width: 32px;
            height: 32px;
            max-width: 32px;
            max-height: 32px;
        }
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        & > svg{
            width: 24px;
            height: 24px;
            max-width: 24px;
            max-height: 24px;
        }
    }
`;
const NavButtonText = styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: inherit;
    margin: 0 10px 0 0;
    white-space: nowrap;
`;
const Side = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    height: inherit;
`;
const LeftSide = styled(Side)`
    flex-grow: 1;
`;
const RightSide = styled(Side)`
    justify-content: flex-end;
    margin-right: 8px;
    margin-left: 30px;
    @media only screen and (${Breakpoints.TABLET}) {
        margin-right: 22px;
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        flex-flow: row-reverse nowrap;
        justify-content: flex-start;
        margin-right: 0;
        & > &:not(:last-child) {
            margin-right: 25px;
        }
    }
`;

// Interfaces
export interface IHeader {
    title?: string
    background?: string
}

// Component
export const Header = (props: IHeader) => {
    const {
        title,
        background,
    } = props;

    const history = useHistory();

    const isLaptopL = useMediaQuery({ query: `(${Breakpoints.LAPTOP_L})` });

    const isSearchPage: boolean = history?.location.pathname === RoutePath.SEARCH_PATH;
    const isMyBooksPath = history?.location.pathname === RoutePath.MY_BOOKS_PATH;

    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [searchOpen, setSearchOpen] = useState<boolean>(isSearchPage);
    const [supportText, setSupportText] = useState('');

    const authAction = useAuthAction();

    const redirectToMyBooks = () => history.push({ pathname: RoutePath.MY_BOOKS_PATH });

    useEffect(() => {
        fetch(APILinks.HEADER_SUPPORT)
            .then((res) => res.text())
            .then((text) => {
                setSupportText(text);
            })
            .catch(() => {});
    }, []);

    const leftSide = (
        <LeftSide>
            {!isLaptopL && (
                <HeaderMobileMenu
                    open={menuOpen}
                    setOpen={setMenuOpen}
                />
            )}
            <HeaderLogo
                title={title}
            />
            {isLaptopL && (
                <HeaderDesktopMenu />
            )}
        </LeftSide>
    );

    const rightSide = (
        <RightSide>
            <HeaderSearch
                open={searchOpen}
                setOpen={setSearchOpen}
            />
            <NavButton
                isActive={isMyBooksPath}
                onClick={authAction(redirectToMyBooks)}
            >
                <NavButtonIcon>
                    <GroupIcon />
                </NavButtonIcon>
                {isLaptopL && (
                    <NavButtonText>Мои книги</NavButtonText>
                )}
            </NavButton>
        </RightSide>
    );

    return (
        <HeaderWrapper
            background={background}
        >
            { supportText && <HeaderSupport dangerouslySetInnerHTML={{ __html: supportText }} /> }
            <HeaderInner>
                {leftSide}
                {rightSide}
            </HeaderInner>
        </HeaderWrapper>
    );
};

Header.defaultProps = {
    title: '',
    background: '',
};
